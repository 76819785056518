<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Unit</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="unitInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Main Unit</label>
                  <model-list-select
                    :list="units"
                    v-model="form.unit_parent_id"
                    option-value="id"
                    option-text="name"
                    placeholder="Select Unit"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.unit_parent_id">{{
                    errors.unit_parent_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Name Unit</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Divider</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="form.divider"
                  />
                  <small class="text-danger" v-if="errors.divider">{{
                    errors.divider[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import { apiGet } from '../../services/api';

export default {
  name: 'FormUnit',
  data() {
    return {
      name: 'Form Unit',
      form: {
        unit_parent_id: null,
        name: '',
        divider: '',
      },
      units: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getVenue();
    this.getUnit();
  },
  methods: {
    onImageSelected(event) {
      this.form.image = event.target.files[0];
    },
    getVenue() {
      apiGet('venue').then(({ data }) => {
        this.venues = data.data;
      });
    },
    getUnit() {
      apiGet('unit').then(({ data }) => {
        this.units = data.data;
      });
    },
    unitInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + 'unit/store', this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal('Error!', res.data.message, 'error');
          } else {
            this.$noty.success('Your unit has been saved!');
            this.$router.push({ name: 'Unit' });
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
